.dt-react.table thead tr th.sort {
  position: relative;
  cursor: pointer;
}

.dt-react.table.table-font-size tr th {
  font-size: 8px;
}

.dt.table-scroll thead { display: block; }
.dt.table-scroll tbody {
  height: 400px; overflow-y:scroll; display:block;
}

.dt-react.table thead tr th.icon:after {
  right: 1em;
  content: "\2191";
  position: absolute;
  opacity: 0.3;
}
.dt-react.table thead tr th.active-desc:after {
  opacity: 1 !important;
}
.dt-react.table thead tr th.icon:before {
  position: absolute;
  opacity: 0.3;
  right: 0.5em;
  content: "\2193";
}
.dt-react.table thead tr th.active-asc:before {
  opacity: 1 !important;
}

.pagination-react {
  list-style: none;
  display: flex;
  padding: 0;
  margin-top: 5px;
}

.pagination-react li {
  padding: 5px;
  cursor: pointer;
}

.pagination-react li:hover {
  padding: 5px;
  cursor: pointer;
}

.pagination-react li.selected a {
  background-color: $primary;
  color: #fff;
}

.pagination-react li a:hover {
  background-color: $primary;
  color: #fff;
}

.pagination-react li.disabled a {
  cursor: not-allowed;
  background-color: #fff;
  color: inherit;
  opacity: .5;
}

.pagination-react li.disabled:hover a {
  cursor: not-allowed;
}

.pagination-react li a {
  padding: 10px;
  border-radius: 5px;
  outline: none;
}
