/* Start Buttons */
.btn-sm.btn-circle {
  width: 25px;
  height: 25px;
  padding: 0px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}
.btn-group-xs > .btn, .btn-xs {
  padding: .2rem .30rem;
  font-size: .75rem;
  line-height: .5;
  border-radius: .2rem;
}
/* End Buttons */

/* Start Form */
.form-control::placeholder {
  opacity: 1;
}
.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
  border-radius: 4px !important;
}
.input-group-text {
  background-color: $bgLight;
}
.custom-control {
  z-index: 0 !important;
}
/* End Form */

/* Start Table */
.table-bordered th, .table-bordered td {
  border: 0px solid #dee2e6;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: $bgLight;
}
/* End Table */

/* Start Text */
small, .small {
  font-size: 85% !important;
}
/* End Text */

.modal .modal-header {
  background-color: $danger;
  color: #fff;
}

/* Custom Theme */
input.form-control, textarea.form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0 !important;
  background: transparent;
  border-radius: 0;
}

input.form-control:focus, textarea.form-control:focus {
  box-shadow: none;
  background: transparent;
}

.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
  border-radius: 0px !important;
}

.floating-label.up-text{
  top: -13px;
  bottom: 10px;
  left: 1px;
  opacity: 1;
  font-size: 10px !important;
  color: $primary;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 1px;
  top: 7px;
  transition: 0.2s ease all;
  font-size: 14px !important
}

.textarea-floating-label.up-text{
  top: -18px;
  bottom: 10px;
  left: 1px;
  opacity: 1;
  font-size: 10px !important;
  color: $primary;
}

.textarea-floating-label {
  position: absolute;
  pointer-events: none;
  left: 1px;
  top: 7px;
  transition: 0.2s ease all;
  font-size: 14px !important
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ced4da;
  border-radius: 0px !important;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  box-shadow: none;
}

.was-validated .form-control:valid, .form-control.is-invalid {
  border-color: #ced4da;
  border-radius: 0px !important;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  box-shadow: none;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: none;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: none;
}